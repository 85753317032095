import * as styles from "./404.module.scss";

import Layout from "../components/Layout";
import { Link } from "gatsby";
import React from "react";
import SeoHelmet from "../components/SeoHelmet";

export default function NotFound() {
  return (
    <Layout>
      <SeoHelmet title="404" />
      <div className={styles.pageContent}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
          <Link to="/">Head Home</Link>
        </p>
      </div>
    </Layout>
  );
}
